
import Vue from 'vue';
import { getCssVar } from '@c360/ui';

// const googleSecret = 'DcdOkMZZPWd50J641ul6_MCG';
const debug = false;

export default Vue.extend({
  components: {
    AppGlobals: () => import('../global/globals.vue'),
    MainFooter: () => import('../global/footer.vue'),
    GaAccountsNew: () => import('../linearadmin/components/gaNew/gaAccountsNew.vue'),
    HeaderLogoC360: () => import('../components/toolbar/headerLogoC360.vue'),
  },
  data: () => ({
    authChecked: false,
    autoAskLocation: false,
    complete: false,
    loggedIn: false,
    saving: false,
    checkStatus: false,
    nextStep: false,
    advertiserId: '',
    userId: '',
    googleClientID:
      process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID ||
      '523663729702-vg84m845lg4603v3cbnhjnru96ishkr6.apps.googleusercontent.com', // todo: make this and public/index.html use the value from .env file(s)
    error: '',
    value: 0,
    showIntro: true,
    gaConnectors: null,
    selectedGA: null,
    gaAccounts: null,
    isAccountLoading: false,
  }),
  mounted(): void {
    if (!this.$route.query.aid) {
      setTimeout(() => {
        this.authChecked = true;
        this.$store.dispatch('showError', 'not a valid landing page');
      }, 1000);
      return;
    }
    this.advertiserId = this.$route.query.aid;
    (window as any).GoogleApiLoaded = this.googleApiLoaded;
    this.LoadGoogleApi();
  },
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    isSaving(): boolean {
      return this.saving || this.$store.state.linearAdmin.googleAnalyticsLoading;
    },
    progressText(): string | null {
      if (this.error) return 'Something went wrong';
      if (!this.authChecked) return 'Checking status...';
      if (this.saving) return 'Saving...';
      if (this.checkStatus) return 'Checking Google Connection...';
      if (this.nextStep) return 'Syncing to Google Account...';
      return null;
    },
    themeColor(): string {
      return getCssVar('--c360-color-compulse');
    },
    hasSelectedView(): boolean {
      if (this.selectedGA) {
        return true;
      } else return false;
    },
    showAccounts(): boolean {
      return !this.isSaving && this.gaAccounts?.length > 0;
    },
  },
  methods: {
    async loadGAAccounts(): Promise<void> {
      this.value = 60;
      this.saving = false;
      this.nextStep = false;
      this.checkStatus = true;
      this.isAccountLoading = true;
      await this.$store
        .dispatch('linearAdmin/getGaAvailableAccount', {
          id: this.advertiserId,
        })
        .then(data => {
          this.gaAccounts = data;
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
          this.error = 'Sorry, no views found.';
        })
        .finally(() => {
          this.value = 80;
          this.checkStatus = false;
          this.isAccountLoading = false;
        });
    },
    selectGaConnectors(): void {
      const ga: Record<string, string> = {};
      if (!this.gaConnectors?.additional) return;
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.account) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.account?.match(/\d+/)[0];
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.ga_account_id) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.ga_account_id;
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga') {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.accountId;
        ga.propertyId = this.gaConnectors?.additional?.id;
        ga.viewId = this.gaConnectors?.primaryId;
      }
      this.selectedGA = ga;
    },
    async loadConnectors(): Promise<void> {
      const connectors = await this.$store.dispatch('linearAdmin/getGaConnector', {
        id: this.advertiserId,
      });
      if (connectors) {
        this.gaConnectors = connectors.data?.[0];
        this.selectGaConnectors();
      }
    },
    selectGa(accountId: string, propertyId: string, viewId?: string): void {
      this.selectedGA = { id: this.advertiserId, accountId, propertyId, viewId };
    },
    async changeGaStatus(): Promise<void> {
      try {
        await this.$store.dispatch('linearAdmin/changeGaStatus', {
          advertiserId: this.advertiserId,
          gaStatus: 'connected',
        });
      } catch (error) {
        this.$store.dispatch('showError', error);
      }
    },
    async updateGaConnector(): Promise<void> {
      this.error = null;
      this.saving = true;
      try {
        if (this.selectedGA) {
          const params = this.selectedGA;
          const resp = await this.$store.dispatch('linearAdmin/addGaConnector', params);

          if (resp.error) {
            this.$store.dispatch('showError', resp.error);
            this.error = resp.error;
          }
          if (resp?.addGaConnector?.created) {
            this.value = 100;
            this.saving = false;
            this.complete = true;
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alreadySignedIn(googleUser: any): void {
      this.authChecked = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _g = (window as any).gapi;
      const auth2 = _g.auth2.getAuthInstance();

      if (debug) {
        // eslint-disable-next-line no-console
        console.log('alreadySignedIn auth2', auth2, 'googleUser', googleUser);
      }

      if (googleUser) {
        this.loggedIn = true;
        const profile = googleUser.getBasicProfile();
        this.userId = profile.getId();
      }
      if (this.autoAskLocation) {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('alreadySignedIn', 'auto ask offline');
        }
        this.askOffline();
      }
    },
    googleApiLoaded(): void {
      setTimeout(() => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _g = (window as any).gapi;
          _g.load('auth2', () => {
            _g.auth2.init({ client_id: this.googleClientID });
            setTimeout(() => {
              // this.initOAuth2(); don't sign into this page, directly ask for offline data access
              this.authChecked = true;
              this.loggedIn = true;
            }, 100);
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('auth2.load', err);
          this.$store.dispatch('showError', err);
        }
      }, 100);
    },
    initOAuth2(): void {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _g = (window as any).gapi;
        _g.signin2.render('google-button', {
          scope: 'https://www.googleapis.com/auth/analytics.readonly',
          width: 240,
          height: 36,
          longtitle: true,
          theme: 'dark',
          onsuccess: this.alreadySignedIn,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onfailure: (err: any): void => {
            if (err.error === 'popup_closed_by_user') {
              this.$store.dispatch('showError', 'Popup was closed');
              this.clear();
              return;
            }
            // eslint-disable-next-line no-console
            console.error('auth2.onfailure', err);
            this.error = err.details || err.message || err.error;
            this.$store.dispatch('showError', err);
          },
        });
        setTimeout(() => {
          this.authChecked = true; // show the 'google sign in' button
          this.autoAskLocation = true; // ask for location after google sign in
        }, 1000);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('auth2.init', err);
        this.$store.dispatch('showError', err);
      }
    },
    async addGAToken(code: string): Promise<void> {
      await this.$store.dispatch('linearAdmin/addGAToken', {
        id: this.advertiserId,
        token: code,
      });
    },
    async askOffline(): Promise<void> {
      try {
        const _g = (window as any).gapi;
        const auth2 = _g.auth2.getAuthInstance();
        this.value = 10;
        const resp = await auth2.grantOfflineAccess({
          scope: 'https://www.googleapis.com/auth/analytics.readonly',
        });
        this.saving = true;
        this.showIntro = false;

        // Add Token for GA
        await this.addGAToken(resp.code);

        this.nextStep = true;
        this.value = 30;

        await this.loadGAAccounts();
        // Change ga-status for advertiser
        await this.changeGaStatus();

        if (this.gaAccounts && !this.isAccountLoading) {
          await this.loadConnectors();
        }
      } catch (err: any) {
        console.error('askOffline error', err);

        if (err.error === 'popup_closed_by_user') {
          this.$store.dispatch('showError', 'Popup was closed');
          this.clear();
        } else {
          this.$store.dispatch('showError', 'sorry, an error occurred');
        }
      }
    },
    LoadGoogleApi(): void {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://apis.google.com/js/platform.js?onload=GoogleApiLoaded';
      document.head.appendChild(script);
    },
    tryAgain(): void {
      this.clear();
      this.askOffline();
    },
    clear(): void {
      this.error = '';
      this.showIntro = true;
      this.nextStep = false;
      this.value = 0;
    },
  },
});
